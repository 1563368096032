import { LogoutOptions } from '@auth0/auth0-react'
import * as React from 'react'
import {
  setTokenCookie,
  userHasGoalsCookieName,
  userHasNonFreeMembershipCookieName,
} from '../../../common/constants/cookies'
import { sessionWasAuthedCookieName } from '../../../common/constants/cookies'
import { useAccount } from './useAccount'
import merge from 'lodash/merge'
import { useCookies } from '../../../hooks/useCookies'
import { clearSurveySessionStorageData } from '../../recommendations/common'
import { analytics } from '../../analytics/analytics'

export const useLogOut = () => {
  const { remove } = useCookies()
  const auth0Logout = useAccount().logout
  return React.useCallback(
    async (options?: LogoutOptions | undefined) => {
      analytics.reset()
      remove(sessionWasAuthedCookieName)
      clearSurveySessionStorageData()
      setTokenCookie()
      remove(userHasGoalsCookieName)
      remove(userHasNonFreeMembershipCookieName)
      const mergedOptions = merge(
        {
          returnTo: window.location.origin,
        },
        options
      )
      return auth0Logout(mergedOptions)
    },
    [auth0Logout, remove]
  )
}
