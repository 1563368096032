import { Auth0Provider, useAuth0 } from '@auth0/auth0-react' // eslint-disable-line no-restricted-imports
import { StringParam, useQueryParams } from 'use-query-params'
import React from 'react'
import { homeRoute, loginRoute } from '../../../common/constants/routes'
import { Auth0ExternalUsageModuleSetter } from '../../Auth0ExternalUsageModuleSetter' // eslint-disable-line no-restricted-imports
import { LoggingInContent } from '../organisms/LoggingInContent'
import { AccountProvider } from './AccountProvider'
import { useLogInState } from '../recoil'
import { v4 } from 'uuid'
import { auth0QueryParamNames, referralSourceQueryParamName } from '../../../common/constants/queryParams'
import {
  isNewUserFirstLoggedInPageLoadSessionStorageKey,
  isNewUserSessionStorageKey,
  loginInitiatedAsSignupSessionStorageKey,
  referralSourceSessionStorageKey,
} from '../../../common/constants/sessionStorage'
import { isFirstLogIn } from '../isFirstLogIn'
import { useTrackDeviceConnectResultInMixpanel } from '../hooks/useTrackDeviceConnectResultInMixpanel'
import { FeatureFlagProvider } from '../FeatureFlagProvider'
import { sendFacebookConversionEventFromClient } from '../../analytics/sendFacebookConversionEventFromClient'
import { DEFAULT_MEMBERSHIP_TIER } from '../../account/utils/membership-tiers'
import { useCookies } from '../../../hooks/useCookies'
import { isInLogInProcessCookieName } from '../../../common/constants/cookies'
import { useShowEmailVerificationModal } from '../hooks/useShowEmailVerificationModal'
import dynamic from 'next/dynamic'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { getQueryParamObjectFromRelativeOrAbsoluteUrlString } from '../utils/getQueryParamObjectFromRelativeOrAbsoluteUrlString'
import { typesafeEntries } from '../../../typescript/guards/typesafeEntries'
import isString from 'lodash/isString'
import { getClientSideEnv } from '../../../env/getEnvs'
import isNil from 'lodash/isNil'
import { useUrl } from '../../../hooks/useUrl'

const LazySetUserTimeZoneOnInitialLogInFirstPageLoad = dynamic(() =>
  import('../atoms/EagerSetUserTimeZoneOnInitialLogInFirstPageLoad').then(
    (x) => x.EagerSetUserTimeZoneOnInitialLogInFirstPageLoad
  )
)

// !! if we ever end up in a state where we try to authenticate or log in with code/state params from auth zero in the
// !! url auth 0 throws login errors and fails to login on subsequent refreshes even after logging in if the params remain
// !! in the url. We need to make sure that 1) if we ever end up in a state where we are trying to log in, and the params
// !! exist, we strip them so they don't end up in the url we redirect the user back to (as this will cause subsequent
// !! refreshes to fail authentication), and 2) we want to make sure that after auth0 ahs processed the state/code from the
// !! url, we always strip them from there so we (hopefully) avoid ever ending up in a position where they could cause
// !! errors on refresh, login, or login + refresh.

function StripAuthParamsFromUrl() {
  const { isLoading } = useAuth0()
  const [params, setParams] = useQueryParams({
    [auth0QueryParamNames.code]: StringParam,
    [auth0QueryParamNames.state]: StringParam,
  })

  React.useEffect(() => {
    if (params[auth0QueryParamNames.code] || params[auth0QueryParamNames.state]) {
      // !!Always strip out code and state params. see note at top of file
      setParams({ [auth0QueryParamNames.code]: undefined, [auth0QueryParamNames.state]: undefined })
    }
  }, [isLoading, params, setParams])
  return null
}
/**
 * always strip /login out in favor for the fallback default home route so as to avoid a situation where we try to
 * redirect back to /login after a successful login, which would create an infinite loop
 */
const replaceLoginWithHomeRoute = (pathname?: string | null) =>
  isNil(pathname) ? pathname : pathname.replace(/\/login/, homeRoute)

const LoginHandler = () => {
  const url = useUrl()
  const pathname = usePathname()
  const { loginWithRedirect } = useAuth0()
  const [logInState] = useLogInState()

  const { set } = useCookies()

  React.useEffect(() => {
    if (logInState.isLoggingIn) {
      const isLoginPage = pathname === loginRoute
      // default appState's postAuthRedirectUri to the current url. allow overriding this when setting loginstate
      const { appState, ...options } = logInState.loginOptions ?? {}
      // redirecting back to login after a successful login creates an infinite loop, so force '/' if we are on login page
      const unsanitizedPostAuthRedirectUri = isString(appState?.postAuthRedirectUri)
        ? appState?.postAuthRedirectUri
        : isString(options.redirectUri)
          ? options.redirectUri
          : isLoginPage
            ? homeRoute
            : url ?? homeRoute

      // !!Always strip out code and state params. see note at top of file
      const params = new URLSearchParams(unsanitizedPostAuthRedirectUri.split('?')[1])
      params.delete('code')
      params.delete('state')
      // in case this is still in the url, get rid of it so it isn't in the redirect. we handle referral source in session storage and app state
      // immediately after page load and onward
      params.delete(referralSourceQueryParamName)

      const paramStringWithoutCodeAndState = params.toString()

      const sanitizedPostAuthRedirectUri = replaceLoginWithHomeRoute(
        pathname ? `${pathname}${paramStringWithoutCodeAndState ? `?${paramStringWithoutCodeAndState}` : ''}` : null
      )

      const currentUrlRedirectValues = pathname
        ? getRedirectValues(replaceLoginWithHomeRoute(`${pathname}?${paramStringWithoutCodeAndState}`))
        : null

      const willBeRedirectedPostAuth = (() => {
        const postAuthLandingUri =
          typeof options?.redirectUri === 'string' && options.redirectUri ? options.redirectUri : homeRoute

        const postAuthLandingValues = getRedirectValues(postAuthLandingUri)

        if (!currentUrlRedirectValues || !postAuthLandingValues) return false

        const allLandingParamsEqualToCurrentUrlParams = typesafeEntries(postAuthLandingValues.queryParamObject).every(
          ([k, v]) => currentUrlRedirectValues.queryParamObject[k] === v
        )

        const allCurrentUrlParamsEqualToLandingParams = typesafeEntries(
          currentUrlRedirectValues.queryParamObject
        ).every(([k, v]) => postAuthLandingValues.queryParamObject[k] === v)

        const landingPathNotEqualToCurrentUrlPath = postAuthLandingValues.pathname !== currentUrlRedirectValues.pathname

        return (
          landingPathNotEqualToCurrentUrlPath ||
          !(allLandingParamsEqualToCurrentUrlParams && allCurrentUrlParamsEqualToLandingParams)
        )
      })()
      if (willBeRedirectedPostAuth) set(isInLogInProcessCookieName, 'true')

      const referralSource = sessionStorage.getItem(referralSourceSessionStorageKey)
      sessionStorage.removeItem(referralSourceSessionStorageKey)

      const realize_login_request_id = v4()

      // set an id for this sign in so as to determine if the user post log in is a new user or an returning user
      // send to BE in options. also send in appState so we can pull it after login and compare with the loginId that
      // comes ack in the jwt to see if this log in led to a new sign up or is just a returning user (if login request id same
      // as BE, new user)
      options.realize_login_request_id = realize_login_request_id

      loginWithRedirect({
        ...options,
        realize_signup_type: options.realize_signup_type ?? DEFAULT_MEMBERSHIP_TIER,
        appState: {
          ...appState,
          postAuthRedirectUri: replaceLoginWithHomeRoute(
            willBeRedirectedPostAuth ? sanitizedPostAuthRedirectUri : undefined
          ),
          realize_login_request_id,
          referralSource,
          login_initiated_as_sign_up: options.screen_hint === 'signup',
        },
      })
    }
  }, [logInState, loginWithRedirect, pathname, set, url])
  return null
}

type RedirectValues = ReturnType<typeof getRedirectValues>

const getRedirectValues = (postAuthRedirectUri: any) => {
  if (typeof postAuthRedirectUri !== 'string') return null
  const queryParamObject = getQueryParamObjectFromRelativeOrAbsoluteUrlString(postAuthRedirectUri)
  const pathname = postAuthRedirectUri.split('?')[0]
  if (!pathname) return null
  return { queryParamObject, pathname }
}

export const auth0scope = 'openid profile email'

export function AuthProvider({ children }: React.PropsWithChildren<{}>) {
  const { NEXT_PUBLIC_AUTH0_DOMAIN, NEXT_PUBLIC_AUTH0_CLIENT_ID, NEXT_PUBLIC_AUTH0_AUDIENCE } = getClientSideEnv()
  const { replace } = useRouter()
  const [{ isLoggingIn }, setLogInState] = useLogInState()
  useTrackDeviceConnectResultInMixpanel()
  useShowEmailVerificationModal()

  const { remove } = useCookies()

  const [isNewSignUpFirstPageLoad, setIsNewSignUpFirstPageLoad] = React.useState(false)

  const [redirectValues, setRedirectValues] = React.useState<RedirectValues | undefined>()

  const searchParams = useSearchParams()
  const pathname = usePathname()

  const setLoginComplete = React.useCallback(() => {
    remove(isInLogInProcessCookieName)
    setLogInState((s) => ({ ...s, postAuthRedirectInProgress: false }))
  }, [remove, setLogInState])

  React.useEffect(() => {
    const noRedirectRequired = redirectValues === null

    const redirectCompleted =
      redirectValues?.pathname === pathname &&
      typesafeEntries(redirectValues?.queryParamObject ?? {}).every(
        ([k, v]) => searchParams && searchParams.get(k) === v
      )

    if (noRedirectRequired || redirectCompleted) {
      setLoginComplete()
    }
  }, [redirectValues, searchParams, pathname, setLoginComplete])

  return (
    <Auth0Provider
      domain={NEXT_PUBLIC_AUTH0_DOMAIN ?? ''}
      clientId={NEXT_PUBLIC_AUTH0_CLIENT_ID ?? ''}
      redirectUri={typeof window !== 'undefined' ? window.location.origin : ''}
      audience={NEXT_PUBLIC_AUTH0_AUDIENCE}
      scope={auth0scope}
      cacheLocation={'localstorage'}
      useRefreshTokens
      onRedirectCallback={async (appState, user) => {
        const { postAuthRedirectUri } = appState ?? {}
        const postAuthRedirectValues = getRedirectValues(postAuthRedirectUri)
        setRedirectValues(postAuthRedirectValues)

        if (isFirstLogIn(appState, user)) {
          // on the first page load of their first session, set their time zone
          setIsNewSignUpFirstPageLoad(true)
          sessionStorage.setItem(isNewUserSessionStorageKey, user?.sub ?? '')
          sessionStorage.setItem(isNewUserFirstLoggedInPageLoadSessionStorageKey, 'true')
          sessionStorage.setItem(
            loginInitiatedAsSignupSessionStorageKey,
            String(!!appState?.login_initiated_as_sign_up)
          )

          sendFacebookConversionEventFromClient({
            event_name: 'CompleteRegistration',
            user_data: {
              em: user?.email,
              external_id: user?.sub,
            },
          })
        }
        // Redirect to the url from state. If that is not set or if it is set to the index page, which is where we/auth0
        // redirects to natively, do nothing

        if (postAuthRedirectUri && postAuthRedirectUri !== homeRoute && !postAuthRedirectUri.includes(loginRoute)) {
          // we key off the cookie to immediately show the spinner, but we can follow the more synchronous
          // postAuthRedirectInProgress when that is set, so as soon as we set this to true, remove the cookie so as
          // soon as the postAuthRedirectInProgress is false, the spinner will disappear
          setLogInState((s) => ({ ...s, postAuthRedirectInProgress: true, referralSource: null }))
          remove(isInLogInProcessCookieName)
          replace(postAuthRedirectUri)
        } else {
          setLogInState((s) => ({ ...s, referralSource: null }))
        }
        // regardless of whether we detect the login as complete, always kill off any related logic/UI after 3 seconds
        // in case something went wrong with the redirect check or it was interrupted, etc.
        setTimeout(() => {
          setLoginComplete()
        }, 3000)
      }}
    >
      <FeatureFlagProvider>
        <AccountProvider>
          {isNewSignUpFirstPageLoad && <LazySetUserTimeZoneOnInitialLogInFirstPageLoad />}
          <StripAuthParamsFromUrl />
          <Auth0ExternalUsageModuleSetter />
          <LoginHandler />
          {isLoggingIn ? <LoggingInContent /> : children}
        </AccountProvider>
      </FeatureFlagProvider>
    </Auth0Provider>
  )
}
