export const franchisePageTheme = {
  colors: {
    raichuOrange: '#F2A83B',
    fleurDeSelCaramel: '#DC8403',
    blankCanvas: '#FFEFD7',
    indocileTiger: '#BB6F00',
    augustMorning: '#FFD79C',
  },
  font: {
    manrope: "'Manrope' !important",
    oswald: "'Oswald' !important",
  },
}
