import { Brand } from 'utility-types'
import { getClientSideEnv } from '../../env/getEnvs'

export type RelativeUrlStringBrand = Brand<string, 'relative-url-string'>

export function isRelativeUrlString(input: any): input is RelativeUrlStringBrand {
  const isString = typeof input === 'string'
  if (!isString) return false
  const isRelative = input.startsWith('/')
  if (!isRelative) return false
  const absoluteUrlOfRelativeUrl = `${getClientSideEnv().NEXT_PUBLIC_ORIGIN_SERVER_URL}${input}`

  try {
    new URL(absoluteUrlOfRelativeUrl)
  } catch (_error) {
    return false
  }
  return true
}
